.listWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 6px;
  padding-top: 2px;
  border: 2px solid #666895;
  background: #2a2a3e;

  .list {
    flex: 1;
    overflow: auto;

    li {
      width: 250px;
      padding: 4px;
      color: #666895;
      border: 1px solid transparent;
      border-bottom: 1px solid #666895;
      margin-bottom: 1px;
      list-style: none;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        background: #5f6282;
        border: 1px solid white;
        color: #e6e7ea;
      }

      &.active {
        color: #e6e7ea;
        background: #5f6282;
        border: 1px solid white;
      }
    }
  }

  .uploader {
    text-align: right;

    label {
      padding: 8px 0;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #e6e7ea;
      border-top: 2px solid #666895;
      cursor: pointer;
      background: #2a2a3e;

      input {
        display: none;
      }
    }
  }
}
