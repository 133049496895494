.player {
  .canvas {
    display: flex;
    border-radius: 6px;
    border: 4px solid black;
    margin-bottom: 16px;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    audio {
      background: white;
      border-radius: 6px;
      flex: 1;
    }
  }
}
